<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>二维码</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>
    <div class="tableInput">
      <span>日期范围：</span>
      <el-date-picker
        type="date"
        v-model="dateTime.date1"
        style="width: 12%"
      ></el-date-picker>
      <span style="margin: 0 5px">-</span>
      <el-date-picker
        type="date"
        v-model="dateTime.date2"
        style="width: 12%"
      ></el-date-picker>
      <el-select v-model="status" style="width: 8% !important; margin: 0 5px">
        <el-option
          v-for="item in statuses"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="type" style="width: 8% !important; margin: 0 5px">
        <el-option
          v-for="item in types"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="QueryContent"
        placeholder="请输入内容"
        style="width: 15%; margin-right: 5px"
      ></el-input>
      <el-button type="success"><i class="el-icon-search"></i>查询</el-button>
    </div>
    <div class="tableBtn">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="dialogVisible = !dialogVisible"
        >新增</el-button
      >
      <el-button type="danger" icon="el-icon-delete">批量删除</el-button>
      <el-button type="primary" icon="el-icon-upload">导出</el-button>
    </div>
    <div class="table">
      <div style="padding: 10px 0">
        <el-table
          ref="multipleTable"
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; border: 1px solid #e5e5e5"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center"> </el-table-column>
          <el-table-column
            prop="creatDate"
            sortable
            label="创建日期"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="type" label="类型" align="center">
          </el-table-column>
          <el-table-column prop="coding" label="编码" align="center">
          </el-table-column>
          <el-table-column prop="user" label="使用人" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="useDate" label="使用日期" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-edit poiner"
                @click="handleEdit(scope.$index, scope.row)"
              ></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length == 0 ? 0 : (page - 1) * length + 1
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              :total="tableFinshNum"
              :current-page="page"
              @current-change="currentChange"
            ></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="类型" prop="type">
          <el-select v-model="ruleForm.type" clearable placeholder="请选择">
            <el-option
              v-for="item in typeopts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编号" required>
          <el-col :span="5">
            <el-form-item prop="serial1">
              <el-input v-model="ruleForm.serial1"></el-input>
            </el-form-item>
          </el-col>
          <el-col
            class="line"
            :span="1"
            style="display: flex; justify-content: center"
            >-</el-col
          >
          <el-col :span="5">
            <el-form-item prop="serial2">
              <el-input v-model="ruleForm.serial2"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      dateTime: { date1: "", date2: "" },
      status: "状态",
      type: "类型",
      statuses: [
        {
          value: "待发布",
          label: "待发布",
        },
        {
          value: "进行中",
          label: "进行中",
        },
        {
          value: "已关闭",
          label: "已关闭",
        },
      ],
      types: [
        {
          value: "活动码",
          label: "活动码",
        },
        {
          value: "订水码",
          label: "订水码",
        },
      ],
      QueryContent: "",

      tableData: [],
      multipleSelection: [],

      tableFinshNum: 0,

      dialogVisible: false,
      ruleForm: {
        type: "",
        serial1: "",
        serial2: "",
      },
      rules: {
        type: [{ required: true, message: "必填" }],
        serial1: [{ required: true, message: "必填" }],
        serial2: [{ required: true, message: "必填" }],
      },
      typeopts: [
        { label: "活动码", value: "活动码" },
        { label: "订水码", value: "订水码" },
      ],
      page: 1,
      length: 10,
    };
  },
  mounted() {
    this.$http
      .post("/qr_code/lst", {
        currentPage: this.page,
        currentLength: this.length,
      })
      .then((res) => {
        console.log(res);
      });
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    optionClick(data) {
      console.log(data);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("多选按钮" + this.multipleSelection.length);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.tableBtn {
  padding: 5px 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}

.table {
  padding: 20px;

  .tableStatus {
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 3px;
    font-weight: bold;
  }
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
</style>
<style lang="less">
.el-button--small {
  font-size: 14px !important;
}
</style>
