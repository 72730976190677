import { render, staticRenderFns } from "./QRcode.vue?vue&type=template&id=544c2642&scoped=true&"
import script from "./QRcode.vue?vue&type=script&lang=js&"
export * from "./QRcode.vue?vue&type=script&lang=js&"
import style0 from "./QRcode.vue?vue&type=style&index=0&id=544c2642&prod&lang=less&scoped=true&"
import style1 from "./QRcode.vue?vue&type=style&index=1&id=544c2642&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544c2642",
  null
  
)

export default component.exports